import mixpanel from "common/mixpanel";
import pageData from "common/page-data";

// Track all Site.Homepage hits
mixpanel.track("ABV2.Site.Homepage", {
  ...pageData.get("ab_data", {}),
  homepageVariant: "v2",
  partner: pageData.get("partner"),
  ip_address: pageData.get("ip_address")
});

// Track all Site.Homepage hits
mixpanel.track(
  "Site.Homepage",
  Object.assign(
    {
      homepageVariant: "v2",
      partner: pageData.get("partner")
    },
    pageData.get("ab_data", {})
  )
);

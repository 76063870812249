import YouTubeIframeLoader from "youtube-iframe";
import mixpanel from "common/mixpanel";
import $ from "jquery";

export default {
  load: ({ domModalId, domVideoId, videoId, showOnload, modalQueue }) => {
    YouTubeIframeLoader.load(YT => {
      const $videoModal = $(`#${domModalId}`);
      let ytPlayer;

      const initYTPlayer = () => {
        ytPlayer = new YT.Player(domVideoId, {
          videoId: videoId || "WNjH59YZV4c",
          playerVars: {
            autoplay: "1"
          }
        });
        ytPlayer.addEventListener("onStateChange", event => {
          // Video has ended
          if (event.data === YT.PlayerState.ENDED) {
            mixpanel.track("YouTube.Ended");
            $videoModal.modal("hide");
          }

          // Video is playing
          if (event.data === YT.PlayerState.PLAYING) {
            mixpanel.track("YouTube.Playing");
          }

          // Video is paused (modal is dismissed)
          if (event.data === YT.PlayerState.PAUSED) {
            mixpanel.track("YouTube.Paused");
          }
        });

        ytPlayer.addEventListener("onReady", () => {
          mixpanel.track("YouTube.Loaded");
        });
      };

      const playVideo = () => {
        if (ytPlayer) {
          ytPlayer.playVideo();
        } else {
          initYTPlayer();
        }
      };

      if (showOnload) {
        if (modalQueue) {
          $videoModal.one("shown.bs.modal", playVideo);
          modalQueue.enqueue($videoModal);
        } else {
          $videoModal.modal("show");
          playVideo();
        }
      }

      $videoModal
        // Play video on modal open
        .on("show.bs.modal", () => playVideo())
        // Pause video on modal close
        .on("hide.bs.modal", e => {
          // Ensure ytPlayer is loaded and that we can pause the video:
          if (ytPlayer && ytPlayer.pauseVideo) {
            ytPlayer.pauseVideo();
          } else {
            e.preventDefault();
          }
        });
    });
  }
};

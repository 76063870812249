const formErrorService = {
  hideFormError($form) {
    $form.find(".js-error-container").hide();
  },

  showErrorInForm(errorText, $form) {
    $form.find(".js-error-container").text(errorText).show();
  }
};

export default formErrorService;

import $ from "jquery";
import { Loader } from "google-maps";

const options = { libraries: ["places", "visualization"] };
const loader = new Loader(process.env.GOOGLE_PLACES_API_KEY, options);

const placesAutoComplete = {
  initializeUsAddress(selector, autoFocus) {
    return this.initializeAddress(selector, autoFocus, "US");
  },

  initializeAddress(selector, autoFocus, country) {
    const mapOpts = {
      componentRestrictions: { country: country || "US" },
      types: ["geocode"]
    };
    return this.initialize(selector, mapOpts, autoFocus);
  },

  initializeUsCityState(selector, autoFocus) {
    return this.initializeCityState(selector, autoFocus, "US");
  },

  initializeCityState(selector, autoFocus, country) {
    const mapOpts = {
      componentRestrictions: { country: country || "US" },
      types: ["(cities)"]
    };
    return this.initialize(selector, mapOpts, autoFocus);
  },

  // Initializes Google Maps Autocompletion for each input field in the selector.
  // Returns the autocomplete objects in an array.
  //
  // Documentation:
  //   https://developers.google.com/maps/documentation/javascript/places-autocomplete
  initialize(selector, initMapOpts = {}, autoFocus) {
    const autocompleteObjects = [];

    loader.load().then(google => {
      const $inputs = $(selector);

      if (!$inputs[0]) {
        const invalidInputError =
          "Cannot initialize an invalid input field " +
          `with jQuery selector: ${selector}`;
        throw new Error(invalidInputError);
      }

      const mapOpts = Object.assign({ types: ["geocode"] }, initMapOpts);

      $inputs.each((i, input) => {
        const $inputParent = $(input).parent();
        const { form } = input;
        // TODO
        const autocomplete = new google.maps.places.Autocomplete(
          input,
          mapOpts
        );
        autocompleteObjects.push(autocomplete);
        if (form && $inputParent) {
          const hiddenFields = {};
          const confirmFields = [
            "latitude",
            "longitude",
            "city",
            "state",
            "zip",
            "country_name",
            "country_iso",
            "street_number",
            "street_name",
            "formatted_address"
          ];

          confirmFields.forEach(name => {
            let $hiddenField = $(form).find(`input[name="${name}"]`);
            if (!$hiddenField.length) {
              $(`<input type="hidden" name="${name}" />`).appendTo(
                $inputParent
              );
              $hiddenField = $(form).find(`input[name="${name}"]`);
            }
            if ($hiddenField.length) {
              hiddenFields[name] = $hiddenField;
            }
          });
          this._registerHiddenFieldsUpdater(autocomplete, hiddenFields);
          $(input).on("change", () => {
            this._resetHiddenFields(hiddenFields);
          });
          $(input).on("keypress", event => {
            if (event.which === 13) {
              event.preventDefault();
              // Update the hidden fields with address components
              google.maps.event.trigger(autocomplete, "place_changed");
              // Give the form some time to update the hidden fields before submitting
              setTimeout(() => {
                $(form).submit();
              }, 500);
            }
          });
        }
      });

      if (autoFocus) {
        setTimeout(() => {
          const firstInput = $($inputs[0]);
          const val = firstInput.val();
          firstInput.val("");
          firstInput.val(val);
          firstInput.focus();
        }, 200);
      }

      $inputs.on("paste", this._onPasteBugFix);
    });

    return autocompleteObjects;
  },

  _onPasteBugFix(evt) {
    let field = evt.currentTarget;
    if (!field) {
      return false;
    }
    return setTimeout(() => {
      field = $(field);
      const val = field.val();
      field.blur();
      field.val(val);
      return field.focus();
    }, 100);
  },

  _resetHiddenFields(fields = {}) {
    Object.keys(fields).forEach(nameKey => {
      if (fields[nameKey]) {
        $(fields[nameKey]).val("");
      }
    });
  },

  _registerHiddenFieldsUpdater(autocomplete, hiddenFields = {}) {
    loader.load().then(google => {
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        const place = autocomplete.getPlace();
        if (place) {
          // reset
          this._resetHiddenFields(hiddenFields);
          // update lat/lng
          if (place.geometry && place.geometry.location) {
            if (hiddenFields.latitude) {
              $(hiddenFields.latitude).val(place.geometry.location.lat());
            }
            if (hiddenFields.longitude) {
              $(hiddenFields.longitude).val(place.geometry.location.lng());
            }
          }
          // update address component hidden fields
          const addr = place.address_components || [];
          addr.forEach(component => {
            const types = component.types || [];
            if (hiddenFields.formatted_address) {
              $(hiddenFields.formatted_address).val(place.formatted_address);
            }
            if (types.length) {
              switch (types[0]) {
                case "street_number":
                  if (hiddenFields.street_number) {
                    $(hiddenFields.street_number).val(component.long_name);
                  }
                  break;
                case "route":
                  if (hiddenFields.street_name) {
                    $(hiddenFields.street_name).val(component.short_name);
                  }
                  break;
                case "locality":
                  if (hiddenFields.city) {
                    $(hiddenFields.city).val(component.long_name);
                  }
                  break;
                case "administrative_area_level_1":
                  if (hiddenFields.state) {
                    $(hiddenFields.state).val(component.short_name);
                  }
                  break;
                case "postal_code":
                  if (hiddenFields.zip) {
                    $(hiddenFields.zip).val(component.long_name);
                  }
                  break;
                case "country":
                  if (hiddenFields.country_name) {
                    $(hiddenFields.country_name).val(component.long_name);
                  }
                  if (hiddenFields.country_iso) {
                    $(hiddenFields.country_iso).val(component.short_name);
                  }
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
    });
  }
};

export default placesAutoComplete;

import "bootstrap/js/dist/modal";
import queryString from "query-string";
import TrackedVideoModal from "common/tracked-video-modal";
import $ from "jquery";
import formErrorService from "forms/errors";
import modalQueue from "common/modal-queue";
import pageData from "common/page-data";
import partnerVideoKeys from "common/partner-video-keys";
import "common/address-search";
import "common/login-modal";
import "common/homepage-mixpanel";

const params = queryString.parse(window.location.search);

$(".js-advertiser-profile-search-form").on("submit", e => {
  const $form = $(e.target);
  const $inputs = $form.find("input[required]");

  for (let i = $inputs.length - 1; i >= 0; i -= 1) {
    const el = $inputs[i];
    if (!$(el).val()) {
      e.preventDefault();
      formErrorService.showErrorInForm(
        "Please enter your name and location in the form below.",
        $form
      );
      break;
    } else {
      formErrorService.hideFormError($form);
    }
  }
});

function requireValidAddressInFormInput(inputSelector) {
  // This is a very simple address validation. It simply checks
  // if there are at least two comma-separated components in the
  // string (an address part1 plus one or more city/state/country tokens).

  $(inputSelector)
    .parents("form")
    .on("submit", function onAddressFormSubmit(e) {
      const textInInput = $(inputSelector).val().toString();
      const addressComponents = textInInput.split(/,\s+/);
      const addressLooksInvalid = addressComponents.length < 2;

      if (addressLooksInvalid) {
        e.preventDefault();
        formErrorService.showErrorInForm(
          "Please enter a complete address, including city and state (e.g. 123 Main St., Durham, NC) or MLS Number",
          $(this)
        );
      } else {
        formErrorService.hideFormError($(this));
      }
    });
}

requireValidAddressInFormInput(".js-require-valid-address");

TrackedVideoModal.load({
  domModalId: "javascript-homepage-modal",
  domVideoId: "javascript-homepage-modal__video",
  videoId: pageData.get("videoId") || partnerVideoKeys[pageData.get("partner")],
  showOnload: params.show_video,
  modalQueue
});

$(".javascript-link--disabled").click(e => {
  e.preventDefault();
});

import modalQueue from "common/modal-queue";
import queryString from "query-string";
import $ from "jquery";

const params = queryString.parse(window.location.search);
const $loginModal = $("#login-modal");
const $mdCouponModal = $("#md-coupon-modal");

if ((params.open_login || params.login_redirect) && $loginModal.length > 0) {
  modalQueue.enqueue($loginModal);
}

if (params.md === "coupon" && $mdCouponModal.length > 0) {
  modalQueue.enqueue($mdCouponModal);
}

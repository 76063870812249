import $ from "jquery";
import "bootstrap/js/dist/modal";

const queue = [];
let currentlyShownModal;

function showModal($modal) {
  currentlyShownModal = $modal;
  $modal.modal("show");
}

$(document).on("hidden.bs.modal", e => {
  if ($(currentlyShownModal).get(0) === e.target && queue.length > 0) {
    showModal(queue.shift());
  }
});

export default {
  queue,
  enqueue($modal) {
    if (typeof $modal.modal !== "function") {
      throw new Error(`${$modal} does not implement .modal()`);
    }
    if (!currentlyShownModal && queue.length < 1) {
      showModal($modal);
    } else {
      queue.push($modal);
    }
    return queue.length - 1;
  }
};

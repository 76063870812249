import $ from "jquery";
import placesAutoComplete from "google/places-auto-complete";
import pageData from "common/page-data";

const possibleProducts = [
  "brand-radius",
  "realtor",
  "realtor-referral",
  "retargeting",
  "insurance-consumer",
  "insurance-referral",
  "local-business-referral",
  "mortgage-referral",
  "mortgage-consumer",
  "tv",
  "tv-zip",
  "digital-out-of-home-zip",
  "wealth-management-consumer",
  "wealth-management-referral",
  "wealth-management-retargeting"
];

function cityStateAutoComplete(product) {
  const productIdentifier = `.js-${product}-profile-search-form-city-state`;
  if ($(productIdentifier)[0]) {
    placesAutoComplete.initializeCityState(
      productIdentifier,
      null,
      pageData.get("currentCountry")
    );
  }
}

possibleProducts.forEach(cityStateAutoComplete);

if (document.querySelectorAll(".listings-search-form-address").length) {
  placesAutoComplete.initializeAddress(
    ".listings-search-form-address",
    false,
    pageData.get("currentCountry")
  );
}
